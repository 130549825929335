
import { ref, defineComponent } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./utils/firebase";
import { User } from "./models/user";
import { DrawerItem } from "./models/drawerItem";

export default defineComponent({
  name: "App",
  setup() {
    const drawer = ref<boolean | null>(null);
    const user = ref<User | null>(null);
    const drawerItems = ref<DrawerItem[]>([]);
    const myError = ref<string | null>(null);
    
    let titlePrefix = "";
    let barColor = "primary";
    if (location.port === "41100") {
      titlePrefix = "【LOCAL】";
      barColor = "";
    } else if (location.hostname.startsWith("dev")) {
      titlePrefix = "【DEV】";
      barColor = "";
    } else if (location.hostname.startsWith("stg")) {
      titlePrefix = "【STG】";
      barColor = "";
    }
    const title = titlePrefix + "サの国 Admin";

    onAuthStateChanged(auth, async (currentUser) => {
      const idTokenResult = await currentUser?.getIdTokenResult(true);
      if (idTokenResult) {
        user.value = new User(currentUser, idTokenResult.claims);
        drawerItems.value = user.value.drawerItems();
      } else {
        drawerItems.value = [new DrawerItem("Home", "/", "mdi-home")];
      }
    });

    return {
      drawer,
      user,
      drawerItems,
      myError,
      title,
      barColor,
    };
  },
});
