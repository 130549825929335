const PREFECTURES = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

interface IEQUIPMENT {
  key: string;
  name: string;
}

const EQUIPMENTS: IEQUIPMENT[] = [
  { key: "drySauna", name: "ドライサウナ" },
  { key: "steamSauna", name: "ウェットサウナ" },
  { key: "tentSauna", name: "テントサウナ" },
  { key: "privateSauna", name: "個室サウナ" },
  { key: "loyly", name: "ロウリュ" },
  { key: "coldWaterBath", name: "水風呂" },
  { key: "waterShower", name: "冷水シャワー" },
  { key: "hotWaterBath", name: "温水風呂" },
  { key: "airBath", name: "外気浴場" },
  { key: "totonoiChair", name: "ととのい椅子" },
  { key: "speakingOK", name: "サウナ室会話OK" },
  { key: "waterDispenser", name: "浴場内給水器" },
  { key: "bringDrinksOK", name: "浴場内飲み物持ち込みOK" },
  { key: "coworkingSpace", name: "コワーキングスペース" },
  { key: "restaurant", name: "お食事処" },
  { key: "parking", name: "駐車場" },
  { key: "tattooOK", name: "タトゥOK" },
  { key: "covid19", name: "コロナ対策" },
];

interface IWEEK {
  key: string;
  longName: string;
  shortName: string;
}

const WEEKS: IWEEK[] = [
  { key: "mon", longName: "月曜日", shortName: "月" },
  { key: "tue", longName: "火曜日", shortName: "火" },
  { key: "wed", longName: "水曜日", shortName: "水" },
  { key: "thu", longName: "木曜日", shortName: "木" },
  { key: "fri", longName: "金曜日", shortName: "金" },
  { key: "sat", longName: "土曜日", shortName: "土" },
  { key: "sun", longName: "日曜日", shortName: "日" },
  { key: "holiday", longName: "祝日", shortName: "祝" },
  { key: "preholiday", longName: "祝前日", shortName: "祝前" },
];

const ADMIN_ROLES = {
  admin: "admin",
  manager: "manager",
  facility_manager: "facility_manager",
  facility_editor: "facility_editor",
  analyst: "analyst",
  customer_service: "customer_service",
  client: "client",
  none: "none",
} as const;
type ADMIN_ROLES = (typeof ADMIN_ROLES)[keyof typeof ADMIN_ROLES];

const FACILITY_INPUT_STATUS = {
  filled: "filled", // 入力が完全に完了した施設でアプリに表示される。
  semifilled: "semifilled", // 主要項目の入力が完了した施設でアプリに表示される。
  wip: "wip", // 入力途中の施設でアプリに表示はされない。(Work In Progress)
  untouched: "untouched", // 抽出しただけの状態の施設でアプリに表示されない。
  hidden: "hidden", // 入力状態がどうであれアプリに表示させたくない施設に利用する。
} as const;
type FACILITY_INPUT_STATUS =
  (typeof FACILITY_INPUT_STATUS)[keyof typeof FACILITY_INPUT_STATUS];

const INQUIRY_STATUS = {
  done: "done",
  postponed: "postponed", // work in progress
  pending: "pending",
  inProgress: "inProgress",
  new: "new",
} as const;
type INQUIRY_STATUS = (typeof INQUIRY_STATUS)[keyof typeof INQUIRY_STATUS];

const FACILITY_REQUEST_STATUS = {
  done: "done",
  postponed: "postponed", // work in progress
  pending: "pending",
  inProgress: "inProgress",
  new: "new",
} as const;
type FACILITY_REQUEST_STATUS =
  (typeof FACILITY_REQUEST_STATUS)[keyof typeof FACILITY_REQUEST_STATUS];

const FACILITY_TAG_TYPE = {
  facility: "facility", // 施設系
  limitation: "limitation", // 制限系
  attention: "attention", // 注意系
  attention_closed: "attention_closed", // 注意系(施設が利用できない場合)
  others: "others", // その他系
} as const;
type FACILITY_TAG_TYPE =
  (typeof FACILITY_TAG_TYPE)[keyof typeof FACILITY_TAG_TYPE];

const FACILITY_REPORT_STATUS = {
  autoaccepted: "auto-accepted", // 自動反映済み
  accepted: "accepted", // 反映済み
  rejected: "rejected", // 反映拒否済み
  unhandled: "unhandled", // 未処理
} as const;
type FACILITY_REPORT_STATUS =
  (typeof FACILITY_REPORT_STATUS)[keyof typeof FACILITY_REPORT_STATUS];

const NOTICE_CATEGORY = {
  notice: "notice",
  event: "event",
  update: "update",
  bug: "bug",
} as const;
type NOTICE_CATEGORY = (typeof NOTICE_CATEGORY)[keyof typeof NOTICE_CATEGORY];

const PLATFORM = {
  ios: "ios",
  android: "android",
} as const;
type PLATFORM = (typeof PLATFORM)[keyof typeof PLATFORM];

const WEBSTORE_ITEM_TYPE = {
  regular: "regular",
  preorder: "preorder",
} as const;
type WEBSTORE_ITEM_TYPE =
  (typeof WEBSTORE_ITEM_TYPE)[keyof typeof WEBSTORE_ITEM_TYPE];

export {
  PREFECTURES,
  IEQUIPMENT,
  EQUIPMENTS,
  IWEEK,
  WEEKS,
  ADMIN_ROLES,
  FACILITY_INPUT_STATUS,
  INQUIRY_STATUS,
  FACILITY_REQUEST_STATUS,
  FACILITY_TAG_TYPE,
  FACILITY_REPORT_STATUS,
  NOTICE_CATEGORY,
  PLATFORM,
  WEBSTORE_ITEM_TYPE,
};
